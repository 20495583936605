import React from "react";
import logo from "../assets/lms-shape-final.png";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const LoadingAnimation = () => {
  const [invertColor, setInvertColor] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setInvertColor((prevState) => !prevState);
    }, 500); // Change color every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white flex items-center justify-center h-[100vh] w-[100vw] font-semibold">
      <div>
        <motion.div
          animate={{ y: [-20, 20, -20], opacity: [1, 1, 1] }}
          transition={{ duration: 20, repeat: Infinity }}
        >
          <img
            className="md:w-[8rem] w-[6rem]"
            style={{
              filter: invertColor ? "grayscale(100%)" : "none",
              transition: "filter 0.5s ease-in-out", // Smooth transition
            }}
            src={logo}
            alt="nuslms"
          />
        </motion.div>
      </div>
    </div>
  );
};
export default LoadingAnimation;
