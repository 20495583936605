import React from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion library

const SubEBCard = ({ img, position, name }) => {
  return (
    <motion.div
      className="h-auto w-[20rem] flex flex-col items-center gap-4 bg-[#7F193B] text-white rounded-lg"
      whileHover={{ backgroundColor: "#E0E0E0", color: "#000000" }} // Apply smooth color change on hover
    >
      <div className="rounded-lg h-auto w-[20rem] overflow-hidden">
        <img className="rounded-t-lg" src={img} alt="asdas" />
      </div>
      <div className="text-2xl font-semibold font-timesNewRoman">{name}</div>
      <div className="text-xl font-semibold font-timesNewRoman mb-5">
        {position}
      </div>
    </motion.div>
  );
};

export default SubEBCard;
