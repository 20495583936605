import React from "react";

import { motion } from "framer-motion";
import event1 from "../assets/Bangladesh Law Students Symposium.png";
import event2 from "../assets/Intra moot logo.png";
import event3 from "../assets/Street Law logo.png";
import event4 from "../assets/Law fest logo.png";
import event5 from "../assets/legaleagle.png";

import eventscover from "../assets/Final Cover Page.png";

const Events = () => {
  const totalEvent = [
    {
      name: "Bangladesh Law Students’ Symposium",
      body: "The North South University Law & Mooting Society (NSULMS) and the Department of Law at North South University have organized the first-ever Law Students’ Symposium. The event's main focus is paper presentations, where law students are encouraged to submit abstracts covering various disciplines of law. A symposium committee will then select the most notable submissions for full-paper presentations. These presentations are set to occur in the presence of a distinguished panel of experts, including legal academics and practitioners from both local and international backgrounds. In addition to presenting students, others interested in the event can register online as observers, free of charge. The symposium is a valuable platform for students to exhibit their research and interact with prominent figures in the legal field.",
      logo: event1,
    },
    {
      name: "Intra NSULMS Moot Court Competition",
      body: "The NSULMS Intra Moot Court Competition is organized by the North South University Law & Mooting Society. This competition provides law students a platform to hone their advocacy skills through simulated court proceedings. Participants will engage in moot court sessions, presenting arguments and analyzing legal issues before a panel of judges. The competition aims to foster critical thinking and practical legal expertise among aspiring lawyers. Stay tuned for further updates on registration and event details.",
      logo: event2,
    },
    {
      name: "NSULMS Street Law and Community Lawyering",
      body: "Street Law and community lawyering initiatives, such as the Street Law and Legal Clinic organized by NSULMS, aim to bridge the gap between legal knowledge and marginalized communities. Through Street Law programs, legal education is brought directly to non-legal audiences, raising awareness about their rights and empowering them to advocate for themselves. Similarly, Legal Clinics provide accessible legal advice and assistance to underserved populations, ensuring they have the information they need to navigate legal challenges. These initiatives not only educate individuals about their rights and duties but also foster a sense of empowerment and active citizenship, ultimately contributing to the establishment of a more just and equitable society.",
      logo: event3,
    },
    {
      name: "NSU Law Fest",
      body: "The NSU Law Fest, organized by the North South University Law & Mooting Society, is set to bring together young legal minds from across Bangladesh for a three-day event. With a diverse lineup of segments, workshops on various topics, and engaging dialogues on career prospects, the fest promises to be an enriching experience. Participants can look forward to activities like the Law Olympiad and a Gala Night, making it a comprehensive platform for networking, learning, and celebration within the legal community.",
      logo: event4,
    },
    {
      name: "Legal Eagle",
      body: "Legal Eagle, organized by NSULMS, offers law students at North South University the chance to refine their speaking and writing abilities through two distinct segments: Legal Debate and Legal Opinion Writing. This competition is tailored to provide students with a broader platform to develop their skills in legal argumentation and opinion formulation. By participating, students can hone their abilities in crafting persuasive arguments and articulating legal opinions effectively.",
      logo: event5,
    },
  ];

  return (
    <div className="flex flex-col font-[TimesNewRoman] mb-[-5rem]   ">
      <div className="w-auto relative md:h-[100vh] h-[50vh] bg-white">
        <div className="absolute inset-0">
          {/* Background image with overlay */}

          <img
            src={eventscover}
            alt="nsulms"
            className="md:w-full md:h-full w-full h-full md:object-cover object-contain"
          />
        </div>
        {/* Centered header text */}
        <div className="absolute inset-0 flex justify-center items-end md:mb-52 mb-32 text-black">
          <h1 className="md:flex md:text-5xl text-2xl">
            <span>Events</span>
          </h1>
        </div>
      </div>

      <div className=" flex flex-col items-center md:gap-16 gap-8 h-auto md:my-10 my-0 mb-28 font-[TimesNewRoman]">
        {totalEvent.map((event, index) => (
          <motion.button
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            whileHover={{ scale: 1.05 }}
          >
            <div
              key={index}
              style={{
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(5.2px)",
                webkitBackdropFilter: "blur(5.2px)",
              }}
              className="bg-[#F3F5F9] md:w-[90vw] md:h-[60vh] w-[80vw] h-auto  rounded-md flex md:flex-row flex-col md:justify-around items-center "
            >
              <div className="flex items-center">
                <motion.button
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  whileHover={{ scale: 1.02 }}
                >
                  <img
                    className="md:w-[25rem] w-[7rem] rounded-md md:mt-0 mt-4"
                    src={event.logo}
                    alt="nsulms logo"
                  />
                </motion.button>
              </div>
              <div className="md:text-start text-center md:w-[30rem] w-[15rem]  flex flex-col md:items-start items-center gap-10 ">
                <h1 className="mt-10 md:text-4xl text-md text-[#7F193B] font-semibold hover:text-slate-800">
                  {event.name}
                </h1>
                <p className="text-justify text-sm">{event.body}</p>
              </div>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default Events;
