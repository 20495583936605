import React from "react";
import { motion } from "framer-motion";
import lazim from "../assets/lazim_cut.png";
import EBCard from "./EBCard";
import { Carousel } from "react-responsive-carousel";
import eb1 from "../assets/lazim-eb.png";
import eb2 from "../assets/maliha-eb.png";
import eb3 from "../assets/tazri-eb.png";
import eb4 from "../assets/rakib-eb.png";
import eb5 from "../assets/toki-eb.png";
import eb6 from "../assets/avishek-eb.png";
import eb7 from "../assets/raisa-eb.png";

const executiveBodyData = [
  {
    img: eb3,
    name: "Zannatin Tazri",
    title: "President",
    txt: "Zannatin Tazri currently serves as the President of NSULMS. Previously, she held the position of Sub-Executive in Media and Promotion, where she played a pivotal role in enhancing the organization's online presence and outreach. Tazri and her team worked tirelessly to promote various events and initiatives, contributing significantly to the success of NSULMS. She aims to continue making strides in the legal field and is dedicated to fostering positive change.",
    email: "zannatin.tazri@northsouth.edu ",
    fb: "https://www.facebook.com/zannatin.tazri.1",
    ig: "https://www.instagram.com/zannatin/",
  },
  {
    img: eb1,
    name: "Sadman Yeasar Alam",
    title: "Joint-Secretary (Public Relations)",
    txt: "Sadman Yeasar Alam is currently serving as the Joint Secretary of NSULMS. He has showcased his mooting skills and proven expertise in legal research. Previously, Sadman served as the Sub-Executive Body of Mooting and was honored with the Best Researcher Award in the 4th Intra Moot Court Competition. He also worked as a research assistant under Professor Dr. Abu Noman Mohammad Atahar Ali in the field of Food Law. Additionally, Sadman has served as the Associate Editor of NSU Law Blog, where he scrutinized various academic articles related to law. He has also contributed as a Teaching Assistant at the Department of Law, North South University. Sadman's interests lie in Constitutional Law and Food Law, and he possesses expertise in SEO writing and digital creative works.",
    email: "sadman.alam11@northsouth.edu",
    fb: "https://www.facebook.com/lazim.khan.7",
    ig: "https://www.instagram.com/_lazimm_/",
  },
  {
    img: eb4,
    name: "Saidy Hasan Rakib",
    title: "Joint-Secretary (Program)",
    txt: "Saidy Hasan Rakib currently holds the position of Joint Secretary (Program) at NSULMS. Previously, he served as a Sub-Executive in Logistics, where he played a crucial role in providing logistical support for major events such as Street Law and Community Lawyering Chapter II. Rakib and his team worked diligently to ensure the successful execution of all events organized by North South University's Department of Law. He has a keen interest in Criminal Law and aspires to bring about positive change in the legal field. Ultimately, Rakib's goal is to pursue a career in judiciary. Additionally, he enjoys networking and meeting new people.",
    email: "saidy.rakib@northsouth.edu",
    fb: "https://www.facebook.com/rakibmridha44",
    ig: "https://www.instagram.com/rakib_mridha_44/",
  },
  {
    img: eb7,
    name: "Raisa Rahman",
    title: "Vice-President (Academics)",
    txt: "Raisa Rahman currently holds the position of Vice President of Academics at the North South University Law and Mooting Society ( NSULMS) . Known for her hardworking nature, vigilance, and teamwork skills, she consistently excels in her endeavors. Notably, she recently showcased her mooting skills by representing NSU at the University of New Delhi in the KK Luthra Moot Court Competition. In addition to her leadership role, she contributes as a Research Assistant to Prof. Md Rizwanul Islam, underscoring her dedication to academic pursuits. Beyond her academic pursuits, she is an avid reader and occasionally indulges her creative side by writing when inspiration strikes. She likes to read, travel, and explore new cultures, but she has a nag for painting.",
    email: "raisa.rahman02@northsouth.edu",
    fb: "https://www.facebook.com/profile.php?id=100007426635063",
    ig: "https://www.instagram.com/rrahman03/",
  },
  {
    img: eb5,
    name: "Saad A Toki Khan",
    title: "Treasurer",
    txt: "Saad A Toki Khan is a student of law in North South University. Currently, he is serving as the Executive Body (Treasurer) of NSULMS. Previously, Toki was part of the Sub-Executive Body of Finance (Panel-105). Additionally, he works as a researcher on a well-known law chamber, 'Ain Sheba'. Toki's interests lie in Criminal law & Tort Law. His dream is to see himself as a judge.",
    email: "Saad.toky@northsouth.edu",
    fb: "https://www.facebook.com/saadatoki6",
    ig: "https://www.instagram.com/saad_a_toki/",
  },
  {
    img: eb2,
    name: "Maliha Mumtaz",
    title: "General Secretary",
    txt: "Maliha Mumtaz is a dedicated law student with a passion for advocacy and international law. She is currently serving as the General Secretary of NSULMS. Maliha has actively engaged in various extracurricular activities that have honed her legal skills and broadened her understanding of the legal landscape. In 2019, Maliha served as the Cadet Ambassador to India from the Bangladesh National Cadet Corps (BNCC), where she showcased exceptional leadership and diplomatic prowess. Throughout her academic journey, Maliha has demonstrated a commitment to excellence and a thirst for knowledge. As a scholarship student at NSU, she has consistently excelled in her studies, earning recognition for her academic achievements. In 2023, Maliha actively participated in several prestigious legal events, including intra-moot competitions, street law initiatives, and community lawyering projects organized by NSULMS. She also showcased her expertise in international arbitration by participating in the BIAC International Inter-University Arbitration Contest organized by the Bangladesh International Arbitration Centre (BIAC).",
    email: "maliha.mumtaz@northsouth.edu",
    fb: "https://www.facebook.com/maliha.mumtaz.52",
    ig: "https://www.instagram.com/maliha__mumtaz__/",
  },
  {
    img: eb6,
    name: "Avishak Das Akash",
    title: "Vice-President (Administration)",
    txt: "Avishak Das is a distinguished individual with a multifaceted background, embodying excellence both in his professional pursuits and in the sporting arena. Currently serving as the Vice President of Administration in the esteemed Sub-EB of Supply & Distribution sector, Avishak showcases remarkable leadership skills and a keen aptitude for organizational management. In addition to his administrative responsibilities, Avishak is a notable figure in the realm of cricket, serving as a vital member of the NSU cricket team. Avishak was also a (BCB) Bangladesh Cricket Board  under -14,16,18 District & Division player",
    email: "avishak.akash@northsouth.edu",
    fb: "https://www.facebook.com/avishak.das.714",
    ig: "https://www.instagram.com/avishak24/",
  },
];

const EBPanel = () => {
  return (
    <div className="bg-white md:h-[100vh] h-auto md:mb-[-1rem]">
      <Carousel
        autoPlay
        interval={10000}
        infiniteLoop
        showArrows={true}
        showStatus={false}
        showIndicators={false}
      >
        <EBCard data={executiveBodyData[0]} />
        <EBCard data={executiveBodyData[1]} />
        <EBCard data={executiveBodyData[2]} />
        <EBCard data={executiveBodyData[3]} />
        <EBCard data={executiveBodyData[4]} />
        <EBCard data={executiveBodyData[5]} />
        <EBCard data={executiveBodyData[6]} />
      </Carousel>
    </div>
  );
};

export default EBPanel;
