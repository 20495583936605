import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import logo from "../assets/lms-shape-final.png";
import video from "../assets/final_video.mp4";
import CarouselCard from "./CarouselCard";
import newSection from "../assets/latestNews.JPG";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HallOfFameCard from "./HallOfFameCard";
import { Link } from "react-router-dom";

import founder1 from "../assets/founder1.jpeg";

import founder3 from "../assets/founder3.png";
import founder4 from "../assets/founder4.jpg";

import sheefa from "../assets/sheefa.jpg";
import navid from "../assets/navid-expanel.png";
import sama from "../assets/sama.JPG";
import malihaa from "../assets/malihaa.png";

import event1 from "../assets/Bangladesh Law Students Symposium.png";
import event2 from "../assets/Intra moot logo.png";
import event3 from "../assets/Street Law logo.png";
import event4 from "../assets/Law fest logo.png";
import event5 from "../assets/legaleagle.png";

const Home = () => {
  const scrollToAboutUs = () => {
    const aboutUsSection = document.querySelector("#aboutus");
    aboutUsSection.scrollIntoView({ behavior: "smooth" });
  };

  const carouselData = [
    {
      img: "https://scontent.fdac13-1.fna.fbcdn.net/v/t39.30808-6/447815672_776212581369394_4871898697963454744_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=dRNWWs8kBm8Q7kNvgFugXHS&_nc_ht=scontent.fdac13-1.fna&oh=00_AYD6MDCgBA5jxKVeuUbav-dFMCgLQZPchKny7DQpPBDWnw&oe=667CD673",
      date: "June 9, 2024",
      title: "Congratulations to all the winners of the Legal Quizathon 2.0!",
      txt: "Their success reflects not only diligence but also a profound understanding of law. Well-deserved praise goes to each of you!",
    },
    {
      img: "https://scontent.fdac13-1.fna.fbcdn.net/v/t39.30808-6/448513235_18239701252253427_4019802194376699317_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=xAt7bRBZv0AQ7kNvgFL12Op&_nc_ht=scontent.fdac13-1.fna&oh=00_AYCnktbWD-1WPWeGOVvsbi08H1pmJxPwgUrDPvFqOrDWOw&oe=667CCD18",
      date: "June 17, 2024",
      title: "Happy Father’s Day",
      txt: "Happy Father’s Day to the beacon of light in our lives, whose steady hands and gentle heart have guided us through stormy seas and sunlit shores alike. You’re the compass that always points us in the right direction, the oak tree whose strength shelters us from life’s winds, and the storyteller whose tales of wisdom echo in our hearts. Today, we celebrate the irreplaceable bond they’ve woven with threads of love, sacrifice, and unwavering support. Here’s to all the father’s, the captain of our family’s ship and the keeper of our fondest memories.",
    },
    {
      img: "https://scontent.fdac13-1.fna.fbcdn.net/v/t39.30808-6/448340176_779752097682109_6201674950384496657_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=KYeHgXsHpMEQ7kNvgGpJb95&_nc_ht=scontent.fdac13-1.fna&oh=00_AYCXU5sUMAhSaFOw39FjfQewtJ8h47kBDixvjq4ADw231A&oe=667CE38D",
      date: "June 15, 2024",
      title: "Day of ‘Arafah",
      txt: "The Day of ‘Arafah, occurring on the 9th of Dhul Hijjah in the Islamic calendar, holds profound significance. It marks the day when Allah (SWT) perfected His religion, bestowed His blessings upon Prophet Muhammad (SAW), and affirmed Islam as the complete way of life. Mentioned in Surah al-Maidah (Quran 5:3), this day is also known as Yawm Al-Waqf (the Day of Standing), as pilgrims stand in supplication before Allah, seeking His mercy.",
    },
    {
      img: "https://scontent.fdac13-1.fna.fbcdn.net/v/t39.30808-6/447203984_771874288469890_7795180127419803775_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=TDEhyFIjF7kQ7kNvgEeymP3&_nc_ht=scontent.fdac13-1.fna&oh=00_AYBokgptlRB54wS2pxbBlw6Ko3FSJvc9Lnqrrtzq6VmovA&oe=667CFF2B",
      date: "MAY 22, 2024",
      title: "TRIAL TACTICS' CRPC CATEGORY",
      txt: "Introducing our first team of flagbearers for the event Trial Tactics in CRPC category. Congratulations to Team D-2 for their exceptional performance and dedication.",
    },
  ];

  const totalEvent = [
    {
      name: "Bangladesh Law Students’ Symposium",
      body: "The North South University Law & Mooting Society (NSULMS) and the Department of Law at North South University have organized the first-ever Law Students’ Symposium. The event's main focus is paper presentations, where law students are encouraged to submit abstracts covering various disciplines of law. A symposium committee will then select the most notable submissions for full-paper presentations. These presentations are set to occur in the presence of a distinguished panel of experts, including legal academics and practitioners from both local and international backgrounds. In addition to presenting students, others interested in the event can register online as observers, free of charge. The symposium is a valuable platform for students to exhibit their research and interact with prominent figures in the legal field.",
      logo: event1,
    },
    {
      name: "Intra NSULMS Moot Court Competition",
      body: "The NSULMS Intra Moot Court Competition is organized by the North South University Law & Mooting Society. This competition provides law students a platform to hone their advocacy skills through simulated court proceedings. Participants will engage in moot court sessions, presenting arguments and analyzing legal issues before a panel of judges. The competition aims to foster critical thinking and practical legal expertise among aspiring lawyers. Stay tuned for further updates on registration and event details.",
      logo: event2,
    },
    {
      name: "NSULMS Street Law and Community Lawyering",
      body: "Street Law and community lawyering initiatives, such as the Street Law and Legal Clinic organized by NSULMS, aim to bridge the gap between legal knowledge and marginalized communities. Through Street Law programs, legal education is brought directly to non-legal audiences, raising awareness about their rights and empowering them to advocate for themselves. Similarly, Legal Clinics provide accessible legal advice and assistance to underserved populations, ensuring they have the information they need to navigate legal challenges. These initiatives not only educate individuals about their rights and duties but also foster a sense of empowerment and active citizenship, ultimately contributing to the establishment of a more just and equitable society.",
      logo: event3,
    },
    {
      name: "NSU Law Fest",
      body: "The NSU Law Fest, organized by the North South University Law & Mooting Society, is set to bring together young legal minds from across Bangladesh for a three-day event. With a diverse lineup of segments, workshops on various topics, and engaging dialogues on career prospects, the fest promises to be an enriching experience. Participants can look forward to activities like the Law Olympiad and a Gala Night, making it a comprehensive platform for networking, learning, and celebration within the legal community.",
      logo: event4,
    },
    {
      name: "Legal Eagle",
      body: "Legal Eagle, organized by NSULMS, offers law students at North South University the chance to refine their speaking and writing abilities through two distinct segments: Legal Debate and Legal Opinion Writing. This competition is tailored to provide students with a broader platform to develop their skills in legal argumentation and opinion formulation. By participating, students can hone their abilities in crafting persuasive arguments and articulating legal opinions effectively.",
      logo: event5,
    },
  ];

  const foundingMembers = [
    {
      name: "Navid Nowroz Shah",
      img: navid,
      title: "Founding President",
      txt: "NSU Law has always been full of potential students and qualified teachers since the beginning. NSULMS had to emerge from the dire need of establishing that existence and to showcase our unified accomplishment at campus and also in the legal arena of Bangladesh. Starting from organizing Intra Moot Court Competitions to the country's first Law Fest and Law Students' Symposium, I am humble to say that we tried to set some precedents. I am extremely grateful to the Faculty Adviser, Founding EBs and Members, for all my experiences and memories while serving the club. I believe my greatest achievement is the unbreakable bond with my mates which was crafted during this wholesome journey. Back then the vision of this student-led organization was to create equal opportunities for every student to master the academic and interpersonal skills. Every person can offer something so we wanted that no one gets left behind. I hope the current and future generation will ensure that this platform continues to help the young learners flourish, complimenting their caliber and capability.",
    },
    {
      name: "Md Nahidul Islam",
      img: founder4,
      title: "Founding Vice President",
      txt: "Being a co-founder, I'm fortunate. we made NSULMS for the  development of mass people through law students. Since, we are committed to serve our people. We took the initiatives only but the rest is depend on future stars who will carry the legacy of present warriors. I hope three branches of NSULMS (co-founders, past EB's and present EB's) can break any ice barrier for touching the gold.",
    },
    {
      name: "Aisha Azmain Sheefa",
      img: sheefa,
      title: "Founding General Secretary",
      txt: "Having a community of our own enthusiastic legal minds who would welcome, protect and advocate the rights of its' members to ensure our freedom of opinion and expressions. Sharing, creating and holding the space where surfing through endless possibilities with an open mind to find legal solutions that respect the cultural, social and aesthetic needs of the society. Before NSULMS came into life, didn’t know if these were possible to experience. Meeting the amazing group of members who wanted the same and working with them was euphoria. Since then NSULMS is Setting precedent for the law students, by the law students and of the law students. Good wishes for future endeavours and great leaders of NSULMS.",
    },
    {
      name: "Maliha Mubashira Chowdhury",
      img: malihaa,
      title: "Founding Treasurer",
      txt: "Every once in a while, you get the opportunity to be surrounded by a cohort of people sharing same vision and dreams. For me, founding NSULMS was such a moment. From the young fresher who used to work day in and day out for the club to the graduate I am now, NSULMS will always be a part of me. It's not just our accomplishments, but also the bonds formed with fellow members that make this journey unforgettable. Amidst the laughter and the chaos, we've built something enduring – a club dedicated to celebrating and showcasing the talents of the law department. As I look back from the nascent days of the club to the immaculate maturity it has reached now, I can only be proud to have played a role in shaping one of NSU’s finest clubs. My heartfelt wish is for NSULMS to continue flourishing, becoming a flagbearer for NSU in the years to come.",
    },
    {
      name: "Atia Marzia Seen",
      img: founder1,
      title: "Founding Research & Publications Secretary",
      txt: "Our NSU Law and Mooting Society journey began with a passion for extracurricular engagement. The inaugural success at the Intra-Moot competition fueled our ambition to set national standards, culminating in the NSU Law Fest, a first in Bangladesh. Through initiatives like Street Law &amp; Community Lawyering, we displayed our desire to empower marginalized individuals with legal knowledge and awareness. We aimed to lead by example, creating a platform for law students nationwide to showcase their talents and unite in a shared vision of excellence. NSU LMS defined my university experience. This is where I made friends and developed meaningful relationships with the faculty, staff, and students as we worked together to tailor and implement programs. The great memories I made at the club will last a lifetime. Managing people is one of the most important life and career skills and the club is a great platform to develop it. I encourage every student to participate in the club to sharpen life skills and hope you have as much fun as I did. I wish you all the best and hope the club continues to be a place that fosters strong friendships as you make impactful contributions to the Department, the University, and wider society.",
    },
    {
      name: "Nafiz Ahmed",
      img: founder3,
      title: "Founding Public Relations Secretary",
      txt: "From the middle of 2017 until my last day as an undergrad, NSULMS was the center of my life at NSU. My time at LMS was marked by one novel organizational struggle following the other since the club was still relatively new. However, as I reminisce about those days, all I can recollect are happy memories. I am grateful for the long and lively conversations my fellow executive members (and friends) and I had with Nasmin madam in her old office room on the sixth floor. It is not lost on me that most of my friends from the University are former NSULMS members. I must thank my beloved former club for the lifelong friendships I formed within its walls.",
    },
    {
      name: "Sunjana Alam",
      img: sama,
      title: "Founding Mooting Coordination Secretary",
      txt: "NSULMS is a name of an emotion for me, a place which has shaped my life in a positive way, from where I have earned love, respect and growth as a good human as well. Being one of the founders it still gives me goosebumps that how far NSULMS has come and how far it will rise in future! Legal minds from NSU has found a place to nourish their skills through here and which gives them an identity to wear. I will always be indebted towards NSULMS & I wish all the glory to it.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState(foundingMembers[0]);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % foundingMembers.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentText(foundingMembers[currentIndex]);
  }, [currentIndex]);

  const [invertColor, setInvertColor] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setInvertColor((prevState) => !prevState);
    }, 5000); // Change color every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const [isInverted, setIsInverted] = useState(true);

  const toggleColor = () => {
    setIsInverted(!isInverted);
  };

  const fameData = [
    {
      img: "https://i.pinimg.com/originals/7b/64/ed/7b64ed7f7940db566ee51cd32cd2bbbc.jpg",
      name: "JANE DOE",
      creds: "Landmark Ruling on ",
      facebook: "",
      linkedin: "",
    },
    {
      img: "https://i.pinimg.com/564x/6b/8b/03/6b8b03dc47a06d524af5b19a55e0999b.jpg",
      name: "lazim",
      creds: "Public ruler",
      facebook: "",
      linkedin: "",
    },
  ];

  return (
    <>
      <div className="flex flex-col h-[100vh] justify-center md:w-full">
        {/* Background video with dark overlay */}
        <div className="absolute inset-0">
          <video
            className="w-full h-full object-cover"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-black opacity-70"></div>
        </div>

        {/* Text on top */}
        <div className="flex flex-col items-center relative text-white text-center font-['TimesNewRoman'] font-semibold">
          <motion.div
            animate={{ y: [-20, 20, -20], opacity: [1, 1, 1] }}
            transition={{ duration: 20, repeat: Infinity }}
          >
            <button>
              <img
                className="md:w-40 md:h-40 w-36 h-36"
                src={logo}
                alt="nsulms"
                style={{
                  filter: isInverted
                    ? "invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
                    : "none",
                  transition: "filter 0.3s ease-in-out", // Adjust timing and easing as needed
                }}
                onClick={toggleColor}
              />
            </button>
          </motion.div>
          <h1 className="md:text-5xl text-2xl font-bold mt-4 md:mx-0 mx-2">
            North South University Law & Mooting Society{" "}
          </h1>
          <i>
            <p className="md:text-2xl text-lg mt-2">
              <h1 className="md:flex md:text-4xl text-2xl text-orange-20">
                <span
                  style={{
                    color: "#fff",
                    textShadow:
                      "0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7F193B, 0 0 20px #7F193B, 0 0 30px #7F193B, 0 0 40px #7F193B, 0 0 55px #7F193B, 0 0 75px #7F193B",
                  }}
                >
                  Setting Precedent
                </span>
              </h1>
            </p>
          </i>
          <Link onClick={scrollToAboutUs}>
            <motion.button
              className="flex flex-row justify-center items-center rounded-xl px-5 py-4 bg-[#7F193B] mt-10"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              whileHover={{ scale: 1.1 }}
            >
              Explore NSULMS
            </motion.button>
          </Link>
        </div>
      </div>
      <section
        id="aboutus"
        style={{
          background:
            "-webkit-linear-gradient(91deg, #fdfdfd 0%, #7f193b 100%)",
        }}
        className=" w-full md:h-[100vh] h-auto font-TimesNewRoman  overflow-hidden font-[TimesNewRoman]"
      >
        <div className="flex flex-col items-center">
          <p
            className="md:text-5xl text-2xl my-10 text-white"
            style={{ position: "relative" }}
          >
            ABOUT US
            <span
              style={{
                position: "absolute",
                bottom: "-4px",
                left: "20%",
                width: "60%",
                borderBottom: "4px solid #7F193B",
              }}
            ></span>
          </p>
        </div>
        <div className="flex md:flex-row flex-col justify-between items-center mx-20">
          <div>
            <motion.div
              animate={{ y: [-20, 20, -20], opacity: [1, 1, 1] }}
              transition={{ duration: 20, repeat: Infinity }}
            >
              <img
                className="md:w-[25rem] w-[15rem]"
                style={{
                  filter: invertColor ? "grayscale(100%)" : "none",
                  transition: "filter 2.5s ease-in-out", // Smooth transition
                }}
                src={logo}
                alt="nuslms"
              />
            </motion.div>
          </div>

          <div className="flex flex-col gap-4 md:text-start text-center md:w-[45rem] w-[20rem] ">
            <h1 className="md:text-2xl text-xl text-white font-bold mt-5  ">
              NORTH SOUTH UNIVERSITY LAW & MOOTING SOCIETY
            </h1>
            <section className="md:text-lg text-md ">
              <p>
                The Society is a student organization devoted to the development
                of excellence in advocacy and legal knowledge. In pursuing this
                goal, the Society shall require the highest standard both as a
                condition of earning membership and as a condition of
                maintaining membership. The Society shall afford all qualified
                students the opportunity to participate in advocacy-related
                activities.
              </p>
              <p className="md:text-start text-start mt-2">
                <strong>Established:</strong> April 16, 2017
              </p>
            </section>

            <section>
              <h2 className="text-start font-semibold">Mission</h2>
              <ul className="text-start text-md md:text-lg mb-10">
                <li>
                  &#8226; Select, train, and prepare exceptional groups of law
                  students to become efficient legal practitioners.
                </li>
                <li>
                  &#8226; Organize Mooting competitions and facilitate
                  participation in external competitions.
                </li>
                <li>
                  &#8226; Provide students with additional training and
                  experience in written and oral advocacy.
                </li>
                <li>
                  &#8226; Encourage students to involve in social development
                  and bring a change in society by taking part in activities
                  including but not limited to Street law, Youth Parliament, and
                  legal clinics.
                </li>
                <li>
                  &#8226; Foster and promote a culture of advocacy excellence
                  within Department of Law of the North South University.
                </li>
                <li>
                  &#8226; Promote relationships and cooperation between law
                  firms and other legal organizations.
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>

      <section
        className="relative flex flex-col items-center md:h-[100vh] h-auto font-[TimesNewRoman] text-[#7F193B] overflow-hidden"
        style={{
          backgroundImage: `url(${newSection})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-80"></div>

        <p
          className="md:text-5xl text-2xl my-10 mt-10 text-white"
          style={{ position: "relative" }}
        >
          LATEST NEWS
          <span
            className=""
            style={{
              position: "absolute",
              bottom: "-4px",
              left: "20%",
              width: "60%",
              borderBottom: "4px solid #7F193B",
            }}
          ></span>
        </p>

        <Carousel
          autoPlay
          interval={3000}
          infiniteLoop
          showArrows={false}
          showStatus={false}
          className="md:w-full"
        >
          <div className="carousel-item">
            <CarouselCard data={carouselData[1]} />
          </div>

          <div className="carousel-item">
            <CarouselCard data={carouselData[0]} />
          </div>
          <div className="carousel-item">
            <CarouselCard data={carouselData[2]} />
          </div>
          <div className="carousel-item">
            <CarouselCard data={carouselData[3]} />
          </div>
        </Carousel>
      </section>
      <section
        id="activities"
        className="relative h-auto flex flex-col items-center  font-[TimesNewRoman] text-[#7F193B] mb-5"
      >
        <p
          className="md:text-5xl text-2xl my-10 text-[#7F193B]"
          style={{ position: "relative" }}
        >
          Activities
          <span
            style={{
              position: "absolute",
              bottom: "-4px",
              left: "20%",
              width: "60%",
              borderBottom: "4px solid #7F193B",
            }}
          ></span>
        </p>
        <div class="grid md:grid-cols-3 grid-cols-1 mt-8 gap-20 mx-20">
          <div class="group p-6 rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out font-[TimesNewRoman]">
            <div class="w-16 h-16 bg-[#fae3ea] border-2 border-[#d5366b] text-[#7F193B] rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#7F193B"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                ></path>
              </svg>
            </div>

            <div class="content mt-7">
              <div class="title text-black text- h5 text-xl font-medium hover:text-[#d5366b] cursor-pointer">
                Mooting
              </div>

              <p class="text-slate-700 mt-3 text-justify">
                Mooting is mock court practice where participants argue cases,
                mimicking real legal settings. It helps law students and pros
                practice advocacy in national and global court simulations,
                refining persuasive skills and legal analysis in a competitive
                arena.
              </p>
            </div>
          </div>

          <div class="group p-6 rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out">
            <div class="w-16 h-16 bg-[#fae3ea] border-2 border-[#d5366b] text-[#7F193B] rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#7F193B"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                ></path>
              </svg>
            </div>

            <div class="content mt-7">
              <div class="title text-black text- h5 text-xl font-medium hover:text-[#d5366b] cursor-pointer">
                Legal Research
              </div>

              <p class="text-slate-700 mt-3 text-justify">
                Scrutinizing legal materials to unravel complexities, seeking
                precedent and scholarly insights to illuminate legal quandaries.
              </p>
            </div>
          </div>

          <div class="group p-6 rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out">
            <div class="w-16 h-16 bg-[#fae3ea] border-2 border-[#d5366b] text-[#7F193B] rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#7F193B"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                ></path>
              </svg>
            </div>

            <div class="content mt-7">
              <div class="title text-black text- h5 text-xl font-medium hover:text-[#d5366b] cursor-pointer">
                Legal Interpretation
              </div>

              <p class="text-slate-700 mt-3 text-justify">
                Scrutinizing statutes, cases, and doctrines, discerning nuanced
                meanings and implications within legal frameworks.
              </p>
            </div>
          </div>

          <div class="group p-6 rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out">
            <div class="w-16 h-16 bg-[#fae3ea] border-2 border-[#d5366b] text-[#7F193B] rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#7F193B"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                ></path>
              </svg>
            </div>

            <div class="content mt-7">
              <div class="title text-black text- h5 text-xl font-medium hover:text-[#d5366b] cursor-pointer">
                Extra & Co-Curricular Events
              </div>

              <p class="text-slate-700 mt-3 text-justify">
                Diversifying pursuits beyond academics, fostering personal
                growth through sports, arts, volunteering, or other interests.
              </p>
            </div>
          </div>

          <div class="group p-6 rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out">
            <div class="w-16 h-16 bg-[#fae3ea] border-2 border-[#d5366b] text-[#7F193B] rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#7F193B"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                ></path>
              </svg>
            </div>

            <div class="content mt-7">
              <div class="title text-black text- h5 text-xl font-medium hover:text-[#d5366b] cursor-pointer">
                Publications
              </div>

              <p class="text-slate-700 mt-3 text-justify">
                Crafting written works that disseminate legal analysis,
                contributing to academic discourse or informing broader
                audiences on legal matters.
              </p>
            </div>
          </div>

          <div class="group p-6 rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out">
            <div class="w-16 h-16 bg-[#fae3ea] border-2 border-[#d5366b] text-[#7F193B] rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#7F193B"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                ></path>
              </svg>
            </div>

            <div class="content mt-7">
              <div class="title text-black text- h5 text-xl font-medium hover:text-[#d5366b] cursor-pointer">
                Member Engagement & Networking
              </div>

              <p class="text-slate-700 mt-3 text-justify">
                Cultivating relationships and involvement within legal circles,
                fostering collaboration with seniors & Alumni, mentorship, and
                professional opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="#activities"
        className="h-auto w-full md:w-full flex flex-col items-center  font-[TimesNewRoman] text-[#7F193B] mb-5"
      >
        <p
          className="md:text-5xl text-2xl my-10 text-[#7F193B]"
          style={{ position: "relative" }}
        >
          Events
          <span
            style={{
              position: "absolute",
              bottom: "-4px",
              left: "20%",
              width: "60%",
              borderBottom: "4px solid #7F193B",
            }}
          ></span>
        </p>
        <div className="hidden md:flex flex-row gap-10 mt-8 mx-20">
          <Carousel
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop
            centerMode
            centerSlidePercentage={100 / 3}
            autoPlay={true}
            className="text-center"
          >
            {totalEvent.map((event, index) => (
              <div
                key={index}
                className="group p-6 mx-3 h-[33rem]  rounded-lg shadow bg-[#F3F5F9] hover:-translate-y-2 transition-all duration-500 ease-in-out font-[TimesNewRoman]"
              >
                <Link to="/events" target="_blank">
                  <div className="flex flex-col items-center gap-10">
                    <img
                      className="w-[20rem] rounded-md"
                      src={event.logo}
                      alt="nsulms"
                    />
                    <h1 className="text-3xl text-[#7F193B] font-semibold text-center hover:text-slate-800">
                      {event.name}
                    </h1>
                  </div>
                </Link>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="md:hidden flex flex-col gap-4 items-center">
          {totalEvent.map((event, index) => (
            <Link to="/events" target="_blank">
              <div
                key={index}
                className="flex flex-col gap-4 bg-[#F3F5F9] h-[15rem] w-[15rem] items-center rounded-md"
              >
                <img
                  className="w-[8rem] h-[8rem] mt-4 rounded-md"
                  src={event.logo}
                />
                <h1 className="text-center font-semibold text-md">
                  {event.name}
                </h1>
              </div>
            </Link>
          ))}
        </div>
        {/* <div className="md:hidden flex flex-col gap-5 mt-8 ">
          <Carousel
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop
            centerMode
            centerSlidePercentage={100 / 1}
            autoPlay={true}
            className="text-center"
          >
            {totalEvent.map((event, index) => (
              <div
                key={index}
                className="flex flex-col items-center w-full group  p-4 mx-3  rounded-lg shadow bg-[#F3F5F9]  font-[TimesNewRoman]"
              >
                <Link to="/events" target="_blank">
                  <div className=" w-[5rem] flex flex-col items-center gap-6">
                    <img
                      className="w-[6rem] h-[8rem] rounded-md"
                      src={event.logo}
                      alt="nsulms"
                    />
                    <h1 className=" w-[5rem ]text-2xl text-[#7F193B] font-semibold text-center hover:text-slate-800">
                      {event.name}
                    </h1>
                  </div>
                </Link>
              </div>
            ))}
          </Carousel>
        </div> */}
      </section>

      {/* Hall of fame */}
      <section className="h-[150vh] md:h-[100vh] md:w-full w-full flex flex-col items-center font-[TimesNewRoman] relative">
        <p
          className="md:text-5xl text-2xl md:mt-10 mt-5 text-[#7F193B] relative"
          style={{ position: "relative", zIndex: 1 }}
        >
          FOUNDING MINDS
          <span
            style={{
              position: "absolute",
              bottom: "-4px",
              left: "20%",
              width: "60%",
              borderBottom: "4px solid #7F193B",
            }}
          ></span>
        </p>
        <div className="md:w-[100vw] h-[100vh] md:h-[100vh] md:mt-0 mt-8  z-1">
          {" "}
          <motion.div
            key={currentText.title}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.5 }}
            transition={{ duration: 0.5, ease: "linear" }}
          >
            <HallOfFameCard className="z-10" fame={currentText} />
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Home;
