import React from "react";

const CarouselCard = ({ data }) => {
  return (
    <>
      <div className="hidden md:grid grid-cols-2 justify-between md:mx-20 bg-black rounded-3xl bg-opacity-50 border-[1px] border-white text-black">
        <div className=" ml-20 w-72 flex items-center m-10">
          <img
            className=" rounded-3xl shadow-lg "
            src={data.img}
            alt="nuslms"
          />
        </div>
        <div className="md:ml-[-10rem] mt-16 flex flex-col gap-4 items-start my-10">
          <div>
            <h1 className="text-lg font-[TimesNewRoman] text-white font-bold">
              <span
                style={{
                  color: "#fff",
                  textShadow:
                    "0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7F193B, 0 0 20px #7F193B, 0 0 30px #7F193B, 0 0 40px #7F193B, 0 0 55px #7F193B, 0 0 75px #7F193B",
                }}
              >
                {data.date}
              </span>
            </h1>
          </div>
          <h1 className="text-4xl md:w-[40rem] w-[40rem] mt-2 font-[TimesNewRoman] text-white font-bold text-start">
            {data.title}
          </h1>
          <p className="text-md w-[40rem] mt-2 font-[TimesNewRoman] text-white  text-start">
            {data.txt}
          </p>
        </div>
      </div>
      <div className="md:hidden flex flex-col gap-4 items-center font-[TimesNewRoman] overflow-wrap  ">
        <div className="h-auto w-[15rem]">
          <img src={data.img} alt="nsulm" className="rounded-2xl h-auto" />
        </div>
        <div className="flex flex-col gap-4 w-[20rem]">
          <h1 className="text-md font-[TimesNewRoman] text-white font-bold ">
            <span
              style={{
                color: "#fff",
                textShadow:
                  "0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7F193B, 0 0 20px #7F193B, 0 0 30px #7F193B, 0 0 40px #7F193B, 0 0 55px #7F193B, 0 0 75px #7F193B",
              }}
            >
              {data.date}
            </span>
          </h1>
          <h1 className="text-2xl font-[TimesNewRoman] text-white font-bold ">
            {data.title}
          </h1>
          <h1 className="text-md font-[TimesNewRoman] text-white font-bold ">
            {data.txt}
          </h1>
        </div>
      </div>
    </>
  );
};

export default CarouselCard;
