import React from "react";
import facultyImage from "../assets/cover photo.jpg";
import facultyImage2 from "../assets/NSU LAW_Day 2_ (206).jpg";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const FacultyAdvisor = () => {
  const texts = [
    {
      txt: "Senior Lecturer, Department of Law Assistant Proctor, North South University Faculty Advisor, NSULMS LLM in Criminal Law and Criminal, University of Edinburgh, UK (2012-2013) Bachelor of Laws (LL.B), South East University, 2011 Recipient of Vice Chancellor’s Gold Medal for securing 3rd position among all undergraduate students of the School of Arts & Social Sciences who graduated in 2011. Alums of the 10th Human Rights Summer School. Member of the Commonwealth Youth Programme.",
      title: "About",
    },
    {
      txt: "The North South University Law & Mooting Society has consistently demonstrated excellence in the legal field. Their dedication and hard work have made them a standout organization since 2017. Members have achieved significant milestones, such as winning prestigious moot court competitions and organizing insightful legal events some of them being the first in our country. The society's commitment to skill development and knowledge sharing is truly commendable. This platform supports budding lawyers, helping them thrive and excel. Their collective efforts and passion have been the driving force behind their numerous successes. The future looks bright as they continue to aim for new heights and greater accomplishments.",
      title: "Message",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState(texts[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentText(texts[currentIndex]);
  }, [currentIndex]);

  return (
    <div className="flex flex-col font-[TimesNewRoman] mb-[-5rem]   ">
      <div className="w-auto relative h-auto md:h-[100vh] bg-black">
        <div className="md:absolute inset-0">
          {/* Background image with overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black opacity-60"></div>
          <img
            src={facultyImage}
            alt="nsulms"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Centered header text */}
        <div className="absolute inset-0 flex justify-center items-center md:items-end mb-20 md:mb-52 text-white">
          <h1 className="text-2xl md:text-5xl font-bold text-center md:mt-0 mt-44">
            NASMIN JABIN NOOR
          </h1>
        </div>
      </div>
      <div
        // style={{
        //   background: "linear-gradient(91deg, #FFFFFF 5%, #FFF8E3 80%)",
        // }}
        className="bg-white flex md:flex-row flex-col md:justify-between items-center md:px-32 md:h-[100vh] h-[160vh]"
      >
        <div className="md:mt-20 mt-10">
          <motion.button
            initial={{ scale: 1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            whileHover={{ scale: 1.05 }}
          >
            <img
              className="md:w-96 md:h-[30rem] w-[15rem] h-[15rem] rounded-xl shadow-md shadow-[#7F193B] "
              src={facultyImage2}
              alt="nsulms"
            />
          </motion.button>
        </div>
        <div className=" flex flex-col md:items-start items-center md:mt-20 mt-5 md:w-[40rem] gap-2">
          <h1 className="flex flex-row md:text-start text-center items-center gap-2 text-2xl font-semibold text-[#7F193B]">
            {/* <span
              style={{
                display: "inline-block",
                width: "60px",
                borderBottom: "4px solid #7F193B ",
                marginLeft: "1px",
              }}
            ></span> */}
            NASMIN JABIN NOOR
          </h1>

          <h1 className="text-xl font-semibold text-[#7F193B]">
            Senior Lecturer & Assistant Proctor
          </h1>
          <motion.div
            className="text-lg text-black text-justify text-rotator flex flex-col gap-2"
            key={currentText.title}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.5 }}
            transition={{ duration: 0.5, ease: "linear" }}
          >
            <h1 className="text-md md:text-start text-center font-semibold text-[#7F193B]">
              {currentText.title}
            </h1>
            <h1 className="text-lg md:text-start md:px-0 px-2 text-center  text-black">
              {currentText.txt}
            </h1>
          </motion.div>
          <h1 className="text-lg   text-[#7F193B]">
            <b>Phone:</b> +880-2-55668200
          </h1>
          <h1 className="text-lg   mt-[-1rem] text-[#7F193B]">
            <b>Email:</b> nasmin.noor@northsouth.edu
          </h1>
        </div>
      </div>
    </div>
  );
};

export default FacultyAdvisor;
