import React from "react";
import SubEbCard from "./SubEbCard";
import kinuma from "../assets/kinuma.jpg";
import tahsin from "../assets/tahsin.jpg";
import maisha from "../assets/maisha.jpg";
import fardin from "../assets/fardin.jpg";
import wasi from "../assets/wasi.jpg";
import nafis from "../assets/nafis.jpg";
import thania from "../assets/thania.jpg";
import ruhul from "../assets/ruhul.jpg";
import prottash from "../assets/prottash.jpg";
import abdullah from "../assets/abdullah.jpg";
import bashir from "../assets/bashir.png";

const SubEBPanel = () => {
  const subEbMembers = [
    { img: abdullah, position: "Service & Production", name: "Abdullah Noor" },
    { img: bashir, position: "Mooting", name: "Bashirul Islam Bihango" },
    { img: fardin, position: "Management", name: "Fardeen Hassan Anton" },
    { img: maisha, position: "Program", name: "Maisha Samiha Chowdhury" },
    { img: prottash, position: "Documentation", name: "Md. Iftekharul Islam" },
    { img: nafis, position: "Logistics", name: "Nafis Arif Shahriar" },
    { img: kinuma, position: "Documentation", name: "Rasnuv Taaseen Kinuma" },
    { img: ruhul, position: "Human Resource", name: "Ruhul Amin Khan" },
    { img: thania, position: "Marketing & Promotion", name: "Tanya Shakur" },
    { img: wasi, position: "Finance", name: "Wasi Parvez Tasin" },
  ];

  return (
    <div className="flex flex-col items-center justify-center gap-10 pt-32 h-auto mb-4">
      <h1 className="text-3xl text-center font-bold font-timesNewRoman hover:text-[#7F193B]">
        SUB-EXECUTIVE BODY MEMBERS
      </h1>
      <div className="md:grid md:grid-cols-3 md:gap-8 flex flex-col gap-4">
        {/* Render cards dynamically */}
        {subEbMembers.map((member, index) => (
          <SubEbCard
            key={index}
            img={member.img}
            position={member.position}
            name={member.name}
          />
        ))}
      </div>
    </div>
  );
};

export default SubEBPanel;
