import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./Components/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Events from "./Components/Events";
import FacultyAdvisor from "./Components/FacultyAdvisor";
import EBPanel from "./Components/EBPanel";
import SubEbPanel from "./Components/SubEbPanel";
import LoadingAnimation from "./Components/LoadingAnimation";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(fakeLoading);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/facultyadvisor" element={<FacultyAdvisor />} />
            <Route path="/ebpanel" element={<EBPanel />} />
            <Route path="/subebpanel" element={<SubEbPanel />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
