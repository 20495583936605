import React from "react";
import founder1 from "../assets/founder1.jpeg";
import { motion } from "framer-motion";

const HallOfFameCard = ({ fame }) => {
  return (
    <div className="flex md:flex-row flex-col md:h-[100vh] h-auto md:justify-between items-center md:px-20 md:gap-20 gap-10 text-[#7F193B]">
      <div className="flex flex-col md:gap-6 gap-4 md:mt-0 mt-2 md:items-start items-center md:w-[40rem] w-[20rem]">
        <h1 className="text-2xl font-semibold md:text-start text-center">
          {fame.name}
        </h1>
        <h1 className="text-xl font-semibold md:text-start text-center">
          {fame.title}
        </h1>

        <h1 className="md:text-md text-sm md:text-justify text-center w-[20rem] md:w-full">
          {" "}
          {fame.txt}
        </h1>
        {/* <h1>Socials</h1> */}
      </div>
      <div className="md:mt-20 mt-5">
        <motion.button
          initial={{ scale: 1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          whileHover={{ scale: 1.05 }}
        >
          <img
            className="md:w-[25rem] md:h-[25rem] h-[15rem] w-[15rem] rounded-md shadow-md"
            src={fame.img}
            alt=""
            style={{
              borderRadius: "57px",
              background: "#e0e0e0",
              boxShadow: "23px 23px 43px #9f9f9f -23px -23px 43px #ffffff",
            }}
          />
        </motion.button>
      </div>
    </div>
  );
};

export default HallOfFameCard;
